<template>
  <click-to-edit
    v-model="scope.row.scale.target_level"
    :number-format="{decimals: 6, space:true}"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.target_level')"
    placeholder=""
    @input="saveElementPropChange(scope.row, 'scale', {target_level:$event})"
  >
  </click-to-edit>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-target-level-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {

    }
  },

  methods: {

  }
}

</script>

<style>

</style>
